body {
  margin: 0;
  background-color: white !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  user-select: none;
  -webkit-user-drag: none;
}

fieldset {
  border: none;
}

.MuiFilledInput-root {
  background-color: #c2d2fb !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.MuiFilledInput-root::before {
  border-bottom: 1px solid #011c62 !important;
}

.MuiInputLabel-root {
  color: #011c62 !important;
}

.MuiIconButton-edgeEnd {
  color: #011c62 !important;
}

.edit-pen-icon {
  filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
  height: 22px;
}

.rotate-180 {
  rotate: 180deg;
}
